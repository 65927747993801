<template>
  <td
    :class="`element-content cell_type_${cell.type}`"
    :data-clone-index="clone"
    :style="elementStyle"
  >
    <div class="time-recording-content" :style="style">
      <div class="time-recording-result" :style="styleResult">
        <output class="font-weight-bold">
          {{ time }}
        </output>
      </div>

      <v-btn-toggle class="time-recording-buttons" v-show="showButtons" dense>
        <v-btn
          :class="`${classes}`"
          :disabled="disabledStart"
          type="button"
          data-content-button-action="time_recording_start"
          :data-locked="lockedStart"
          small
          @click="startRecording"
        >
          <v-icon>
            mdi-play
          </v-icon>
        </v-btn>
        <v-btn
          :class="`${classes}`"
          :disabled="disabledStop"
          type="button"
          data-content-button-action="time_recording_stop"
          :data-locked="lockedStop"
          small
          @click="stopRecording"
        >
          <v-icon>
            mdi-stop
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </div>
  </td>
</template>

<script>
export default {
  name: 'ChecklistCellTypeTimeRecording',
  props: [
    'x',
    'y',
    'cell',
    'checklist',
    'state',
    'clone',
    'dataViewer',
    'data'
  ],
  computed: {
    elementStyle() {
      let style = false
      const color = this.dataViewer.temporary_background_color ||
      this.dataViewer.background_color ||
      this.cell.settings.cell_background_color
      if (color) {
        style = `background-color: ${color};`
      }
      return style
    },
    style() {
      let style = false
      const outlineColor = this.dataViewer.outline_color
      if (outlineColor) {
        style = `outline: 1px solid ${outlineColor};`
      }
      return style
    },
    classes() {
      return this.state.classes
    },
    disabledStart() {
      if (this.status === 0) {
        return this.state.disabled
      }
      return true
    },
    disabledStop() {
      if (this.status === 1) {
        return this.state.disabled
      }
      return true
    },
    lockedStart() {
      if (this.status === 0) {
        return false
      }
      return 'true'
    },
    lockedStop() {
      if (this.status === 1) {
        return false
      }
      return 'true'
    },
    value() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      let value = builder.getCellValue(index, this.cell, this.clone, {
        started: false,
        stopped: false,
        'start_timestamp': null,
        'start_date_time': null,
        'start_date': null,
        'start_time': null,
        'stop_timestamp': null,
        'stop_date_time': null,
        'stop_date': null,
        'stop_time': null,
        'result_timestamp': null,
        'result_time': '00:00:00',
        'result_hours': 0,
        'result_minutes': 0,
        'result_seconds': 0
      })
      try {
        if (typeof value === 'string') {
          value = JSON.parse(value)
        }
      } catch (ex) {
        // Keine Aktion nötig
      }
      return value
    },
    time() {
      let time = '00:00:00'
      const builder = this.data.ChecklistBuilder
      if (this.status === 3) {
        time = builder.getTime(this.value.result_timestamp, { UTC: true }) || '00:00:00'
      }
      return time
    },
    status() {
      let status = 0
      if (this.value.started) {
        status += 1
      }
      if (this.value.stopped) {
        status += 2
      }
      return status
    },
    styleResult() {
      let style = ''
      if (this.status !== 3) {
        style += 'display: none;'
      }
      return style
    },
    showButtons() {
      return (this.status !== 3)
    },
    styleButtons() {
      let style = ''
      if (this.status === 3) {
        style += 'display: none;'
      }
      return style
    }
  },
  methods: {
    getPreviousValue(index, builder) {
      let defaultValue = {}
      try {
        const editorValue = builder.getLocalization(this.cell, 'preset_value')
        defaultValue = JSON.parse(editorValue)
      } catch (ex) {
        // Keine Aktion benötigt
      }
      return builder.getCellValue(
        index,
        this.cell,
        this.clone,
        defaultValue
      )
    },
    startRecording() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = this.getPreviousValue(index, builder)
      const date = new Date()
      const value = builder.extend()({}, previousValue)
      value.started = true
      value['start_timestamp'] = +date
      value['start_date_time'] = builder.getDateTime(date) || 'N/A'
      value['start_date'] = builder.getDate(date) || 'N/A'
      value['start_time'] = builder.getTime(date) || 'N/A'
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    },
    stopRecording() {
      const builder = this.data.ChecklistBuilder
      const index = this.checklist.index
      const previousValue = this.getPreviousValue(index, builder)
      const date = new Date()
      const value = builder.extend()({}, previousValue)
      value.stopped = true
      value['stop_timestamp'] = +date
      value['stop_date_time'] = builder.getDateTime(date) || 'N/A'
      value['stop_date'] = builder.getDate(date) || 'N/A'
      value['stop_time'] = builder.getTime(date) || 'N/A'
      value['result_timestamp'] = value.stop_timestamp - value.start_timestamp
      builder.evalValues({
        index: index,
        dataEditor: this.cell,
        dataViewer: this.dataViewer,
        cloneIndex: this.clone,
        encode: false,
        saveCallback: undefined,
        ignoreAutoSaveSetting: false,
        value: value,
        previousValue: previousValue
      })
    }
  }
}
</script>
